@import url('https://fonts.googleapis.com/css2?family=Prompt:wght@200;400;500&display=swap');

:root {
  --label: #0078D4;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body, span {
  font-family: 'Prompt', sans-serif;
}
/* 
h2,
h3,
h4,
h5,
h6 {
  color: #696969;
  font-family: 'Prompt', sans-serif;
}
 */
.container {
  width: 1100px;
  margin: 0px auto 0 auto;
  margin-bottom: 40px;
}

.header {
  margin-bottom: 60px;
  text-align: center;
  position: sticky;
  top: 0;
  z-index: 9;
}

.mobile-header {
    /* margin-bottom: 60px; */
    text-align: center;
    position: sticky;
    top: 0;
    z-index: 9;
}

.headerText {
  /* font-size: 1.5em; */
  font-size: 28px;
}
/* 
h2 {
    font-size: larger;
    font-weight: 600;
}
 */
.textLabel {
  font-weight: 500;
}

.dropzone {
  /* margin-bottom: 40px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* min-height: 20vh; */
  height: 245px;
  margin: 12px 12px 12px 12px;
  /* margin-top: 10px !important; */
}

.dropzone>button {
  margin-top: 8px;
  padding: 8px;
}

.download ul {
  margin-top: 20px;
}

/* File Path Details */
.details {
  margin-top: 60px;
  margin-bottom: 60px;
  padding: 20px 10px;
  border-radius: 5px;
  /* background: rgba(241, 241, 241, .1); */
  background-color: rgba(241, 241, 241, .7);
}

.mobile-details {
    margin-top: 60px;
    margin-bottom: 60px;
    margin-left: 10px;
    margin-right: 10px;
    padding-top: 7px;
    border-radius: 5px;
    /* background: rgba(241, 241, 241, .1); */
    background-color: rgba(241, 241, 241, .7);
    border: 1px solid #ccc;
    border-radius: 5px;    
}

/* Result Border */
.resultBorder {
    border: 1px solid #ccc;
    border-radius: 5px;    
    padding-top: 40px;
    padding-bottom: 20px;
    /* background: rgba(2, 63, 22, 0.1);; */
}

.mobile-resultBorder {
    border: 1px solid #ccc;
    border-radius: 5px;    
    padding-top: 30px;
    padding-bottom: 20px;
    margin-left: 10px;
    margin-right: 10px;
    /* background: rgba(2, 63, 22, 0.1);; */
    
}

/* Download Button */

.btnDownload {
  padding: 12px;
  background: var(--label);
  border-radius: 12px;
  font-size: 20px;
  text-align: center;
}



.titleDownload {
  margin-top: 40px;
  margin-bottom: 20px;
}

.headtitle {
  margin-top: 50px;
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 500;
  color: gray;
}

.mobile-headtitle {
    margin-top: 50px;
    margin-bottom: 15px;
    font-size: 18px;
    font-weight: 500;
    color: gray;
    text-align: center;
  }

.resultTopics {
  padding: 20px;

}

.mobile-resultTopics {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
}


.resultDetails {
    /* padding: 17px; */
    padding-top: 23px;
    padding-left: 20px;
    font-size: 18px;
    font-weight: 500;
    color: #1A1A1A;    
}

.mobile-resultDetails {
    /* padding: 17px; */
    width: 100vw;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 10px; 
    font-size: 14px;
    font-weight: 500;
    color: #1A1A1A;
    border-top: 1px solid #ccc;    
    border-bottom: 1px solid #ccc;    
    background-color: white;
    text-align: center;
}

.verifyDetails {
  margin-top: 40px;
}

.labelTitle {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}

.mobile-labelTitle {
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: left;
}

.contactTel {
  font-size: 2rem;
  /* color: rgba(0, 120, 212,.8) */
  color: #696969;
}

.footer {
  margin-top: 0px;
  /* background: rgba(241, 241, 241, 1) */
  /* background: '#F4F4F4'; */
  border-top: 1.5px solid #E0E0E0;  
}

.copyRight {
  /* background-color: black; */
  background: #F5F5F5;
  border-top: 1.5px solid #E0E0E0;
}

/* PDF Style */
.docFilePath {
  margin-top: 40px;
}

.doctitleStatus {
  margin-top: 30px;
  margin-bottom: 18px;
}

.doctitle {
  margin-top: 30px;

}